<template>
  <div
    class="group flex items-start my-2 hover:bg-bg_color cursor-pointer"
    @click="emit('click')"
  >
    <NuxtImg
      provider="cacheIpx"
      preset="modified"
      loading="lazy"
      :src="material.image"
      :alt="material.name"
      width="48"
      height="48"
      class="w-12 h-12 border border-text-200 rounded object"
    />
    <div class="flex-auto text-sm pr-1 overflow-hidden">
      <div class="truncate">{{ material.name }}</div>
      <div class="flex items-center">
        <div>{{ material.average_rate || 0 }}</div>

        <v-icon icon="fa: fa-solid fa-star" :size="16" class="text-rate mx-1" />
        <div v-if="material.store?.price_visible" class="mr-auto">
          {{ material.stringPrice }}
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Material } from '~~/composables/useMenuModel'

defineProps<{ material: Material }>()

const emit = defineEmits(['click'])
</script>
